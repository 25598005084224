import React from "react";

import Layout from "../components/layout";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function NotFoundPage() {
  const classes = useStyles();
  return (
    <Layout page="notFound">
      <Grid
        container
        justify="center"
        style={{ marginTop: "15%" }}
        className={classes.myStyle}
      >
        <h1>404: Not Found</h1>
      </Grid>
      <Grid container justify="center" className={classes.myStyle}>
        <p>You just hit a route that doesn&#39;t exist</p>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  myStyle: {
    fontFamily: theme.palette.fontFamily.Reef,
  },
}));
